import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-images';
import Gallery from 'react-photo-gallery';

import styles from './style.module.css';

const { string, } = PropTypes;

class CustomLightbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: 0,
    };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }

  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    });
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }

  gotoPrevious() {
    const { currentImage } = this.state;
    this.setState({
      currentImage: currentImage - 1,
    });
  }

  gotoNext() {
    const { currentImage } = this.state;
    this.setState({
      currentImage: currentImage + 1,
    });
  }

  render() {
    const { root, images, width, height, columns } = this.props;
    const { currentImage, lightboxIsOpen } = this.state;
    const photos = images.split(' ').map(src => ({
      width: parseInt(width),
      height: parseInt(height),
      src: `/images/${root}/${src}`,
    }));
    const col = columns ? columns :(photos.length  > 2 ? 3 : photos.length); 

    if (photos.length > 0) {
      return (
        <div className={styles.lightbox}>
          <Gallery
            photos={photos}
            onClick={this.openLightbox}
            columns={col}
          />
          <Lightbox
            images={photos}
            onClose={this.closeLightbox}
            onClickPrev={this.gotoPrevious}
            onClickNext={this.gotoNext}
            currentImage={currentImage}
            isOpen={lightboxIsOpen}
            enableKeyboardInput
          />
        </div>
      );
    }
    return null;
  }
}

CustomLightbox.defaultProps = {
  root: '',
  images: '',
  width: 4,
  height: 3,
  columns: null,
};

CustomLightbox.propTypes = {
  root: string,
  images: string,
};

export default CustomLightbox;
