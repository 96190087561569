const menu = [
  {
    key: 'home',
    text: 'Home',
    icon: 'home',
    link: '/',
  },
  {
    key: 'projects',
    text: 'Projects',
    icon: 'appstore',
    link: '/projects',
  },
  {
    key: 'portfolio',
    text: 'Portfolio',
    icon: 'project',
    link: '/portfolio',
    target: '_blank',
  },
  {
    key: 'resume',
    text: 'Resume',
    icon: 'profile',
    link: '/resume',
    target: '_blank',
  },
  /*
  {
    key: 'about',
    text: 'About',
    icon: 'user',
    subMenu: [
      {
        key: 'me',
        text: 'About Me',
        link: '/about',
      },
      {
        key: 'resume',
        text: 'Resume',
        link: '/resume',
        target: '_blank',
      },
    ],
  },
  {
    key: 'contact',
    text: 'Contact',
    icon: 'phone',
    link: '/contact',
  },
  */
];

export default menu;
