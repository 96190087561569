import React, { Component } from 'react';
import { Menu, Icon } from 'antd';
import PropTypes from 'prop-types';
import config from 'react-global-configuration';
import history from 'customHistory';

import styles from './style.module.css';

const { string } = PropTypes;

const { Item, SubMenu } = Menu;
const dataLinkKey = 'data-link';

class MenuComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: '',
    };
  }

  componentDidMount() {
    const { currentPath: current } = this.props;
    this.setState({
      current,
    });
  }

  handleClick = (e) => {
    const { item, key } = e;
    const { props } = item;

    if (dataLinkKey in props) {
      this.setState({
        current: key,
      });

      history.push(props[dataLinkKey]);
    }
  }

  getMenuItem = (item) => {
    const {
      key, icon, text, link, target,
    } = item;
    const iconElement = icon ? <Icon type={icon} /> : null;
    const linkElement = target
      ? (
        <a href={link} target={target} rel="noopener noreferrer">
          {iconElement}
          {text}
        </a>
      )
      : (
        <span>
          {iconElement}
          {text}
        </span>
      );

    const itemAttributes = target ? {} : {
      [dataLinkKey]: link,
    };

    return (
      <Item key={key} {...itemAttributes}>
        {linkElement}
      </Item>
    );
  }

  getSubMenuItem = (item) => {
    const {
      key, text, icon, subMenu,
    } = item;
    const iconElement = icon ? <Icon type={icon} /> : null;
    const subMenuItems = subMenu.map(e => this.getMenuItem(e));
    return (
      <SubMenu
        key={key}
        title={(
          <span className="submenu-title-wrapper">
            {iconElement}
            {text}
          </span>
        )}
      >
        {subMenuItems}
      </SubMenu>
    );
  }

  getMenuItems = () => {
    const menuConfig = config.get('menu');
    const menuItems = [];
    for (const item of menuConfig) {
      if ('subMenu' in item) {
        menuItems.push(this.getSubMenuItem(item));
      } else {
        menuItems.push(this.getMenuItem(item));
      }
    }
    return menuItems;
  }

  render() {
    const { current } = this.state;
    const items = this.getMenuItems();
    return (
      <Menu
        role='navigation'
        aria-label='Menu'
        className={styles.menu}
        onClick={this.handleClick}
        selectedKeys={[current]}
        mode="horizontal"
      >
        {items}
      </Menu>
    );
  }
}

MenuComponent.defaultProps = {
  currentPath: 'home',
};

MenuComponent.propTypes = {
  currentPath: string,
};

export default MenuComponent;
