const PROMISE_RESOLVED = 'resolved';
const PROMISE_REJECTED = 'rejected';

const reflect = promise => promise.then(
  v => ({
    data: v,
    status: PROMISE_RESOLVED,
  }),
  e => ({
    error: e,
    status: PROMISE_REJECTED,
  }),
);

const loadMarkdownFile = fileName => (
  new Promise(((resolve, reject) => {
    try {
      const mdUrl = require(`../config/markdown/projects/${fileName}.md`);

      fetch(mdUrl)
        .then(r => r.text())
        .then(resolve);
    } catch (err) {
      reject(fileName);
    }
  }))
);


const resolvePromises = (allPromises) => {
  const results = [];
  const promises = Object.keys(allPromises);
  let completedPromises = 0;
  return new Promise((resolve, reject) => {
    promises.forEach((key) => {
      reflect(allPromises[key]).then((value) => {
        const { status, data } = value;
        if (status === PROMISE_RESOLVED) {
          results[key] = data;
        }
        completedPromises += 1;
        if (completedPromises === promises.length) {
          resolve(results);
        }
      }).catch((error) => {
        reject(error);
      });
    });
  });
};

export default { loadMarkdownFile, resolvePromises };
