import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import config from 'react-global-configuration';

import Meta from 'components/Meta';

import styles from './style.module.css';

const {
  string, shape,
} = PropTypes;

const Home = (props) => {
  const { location: currentPathData } = props;
  const appName = config.get('appName');
  const homePageDescription = config.get('homePageDescription');
  const authorPic = config.get('authorPic');

  const metaData = {
    title: 'Home',
    currentPathData,
  };
  const colSizes = {
    xs: 24,
    sm: 24,
    md: 10,
    lg: 10,
  };

  return (
    <Row className={styles.home} type="flex" justify="center">
      <Meta {...metaData} />
      <Col {...colSizes} style={{ display: 'flex', justifyContent: 'center' }}>
        <span
          style={{
            backgroundImage: `url('/images/${authorPic}')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
          }}
          className={styles.avatar}
        />
      </Col>
      <Col {...colSizes}>
        <p className={styles.heading}>
          <span className={styles.greeting}>
            Hello, I&apos;m
          </span> 
          <span className={styles.title}>
            {appName.split(' ')[0]}
          </span>
        </p>
        <p className={styles.intro}>{homePageDescription}</p>
      </Col>
    </Row>
  );
};

Home.propTypes = {
  location: shape({
    pathname: string.isRequired,
  }).isRequired,
};

export default Home;
