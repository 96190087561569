import React from 'react';
import PageTemplate from 'components/PageTemplate';

const TemplateWrapper = (PageComponent) => {
  const Wrapper = props => (
    <PageTemplate {...props}>
      <PageComponent {...props} />
    </PageTemplate>
  );
  return Wrapper;
};

export default TemplateWrapper;
