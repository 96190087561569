import menu from './menu';
import projects from './projects';

const config = {
  appName: 'Fatima Rafiqui',
  appDomain: 'https://www.fatimarafiqui.com',
  appDescription: 'Fatima Rafiqui is a UX/UI designer and developer from India',
  appKeywords: 'fatima, rafiqui, fatimarafiqui, developer, designer, UX/UI',
  appAuthor: 'fatimarafiqui',
  appAuthorSite: 'https://fatimarafiqui.com',
  appAuthorEmail: '',
  authorPic: 'profile.png',
  redirectUrls: {
    twitter: 'https://twitter.com/fatima_rafiqui',
    github: 'https://github.com/fatimarafiqui',
    linkedin: 'https://www.linkedin.com/in/fatimarafiqui',
    resume: 'http://docs.fatimarafiqui.com',
    portfolio: 'http://docs.fatimarafiqui.com/portfolio',
  },
  menu,
  projects,
  homePageDescription: 'When I am not updating my website, I work as a Front End Developer at 1MG, travel to different places or indulge myself in calligraphy. I have an educational background in engineering and a discovered affinity for design. In the years after that, I decided to work on my skills in the design sector in a more professional setting. On a personal front, I bring along with me a broad spectrum of experiences which has made me a better listener and a more tolerant individual; felt like I gained a sixth sense! Days and hours of research, innumerable cappuccinos and multiple overnight conversations with my best friend later, I zeroed down on my choice of pursuing a career in the field of Human Computer Interaction. My cat agreed too; she thinks I could do better with some understanding of the world she is the queen of. Want to know more? I am always up for a conversation! Feel free to drop a message!',
};

// 'When I am not updating my website, I work as a UI/UX Developer at 1MG, travel to different places or indulge myself in calligraphy. I have an educational background in engineering and a discovered affinity for design. It was upon my pursuance of an engineering degree that I came across the branch of human computer interaction. In the years after that, I decided to work on my skills in the design sector in a more professional setting. I participated in the Google Summer of Code initiative as a UI/UX Developer and helped design the interface of an application called FirstAide, to help victims of sexual violence. Thanks to the quality inputs I received at every stage of the project, I was introduced to the human side of design; and how, even small changes affected user responses in big capacities. Not only did the experience make me more sensitive to the user needs, it improved the quality of my work. On a personal front, it made me a better listener and a more tolerant individual; felt like I gained a sixth sense! Shortly after that, I was involved with Oracle India Pvt as a co-op and then moved to ZNSoftech as a UI/UX Developer and contributed to the design and development for various applications. Days and hours of research, innumerable cappuccinos and multiple overnight conversations with my best friend later, I zeroed down on my choice of pursuing a career in the field of Human Computer Interactions. My cat agreed too; she thinks I could do better with some understanding of the world she is the queen of. Want to know more? I am always up for a conversation! Feel free to drop a message!',

export default config;
