import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import config from 'react-global-configuration';

import history from 'customHistory';
import Menu from 'components/Menu';
import CustomFooter from 'components/CustomFooter';

import styles from './page.module.scss';

const {
  string, shape, oneOfType, arrayOf, node,
} = PropTypes;
const {
  Header, Content,
} = Layout;
const menuUrlMap = {
  '': 'home',
  project: 'projects',
  projects: 'projects',
};
const redirectToHome = () => { history.push('/'); };

const PageTemplate = (props) => {
  const { location, children } = props;
  const appName = config.get('appName');
  const { pathname } = location;
  const path = pathname.split('/')[1];
  const currentPath = Object.keys(menuUrlMap).includes(path) ? menuUrlMap[path] : '';

  return (
    <Layout>
      <Header className={styles.header}  role='banner'>
        <span
          role="link"
          tabIndex={0}
          className={styles.logo}
          onClick={redirectToHome}
          onKeyPress={redirectToHome}
        >
          <img src="/images/logo.png" alt={appName} title={appName} />
        </span>
        <Menu currentPath={currentPath} />
      </Header>
      <Content className={styles['page-content']} role='main'>
        {children}
      </Content>
      <CustomFooter role="contentinfo" />
    </Layout>
  );
};

PageTemplate.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
  location: shape({
    pathname: string.isRequired,
  }).isRequired,
};

export default PageTemplate;
