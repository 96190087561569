import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Row, Col, Carousel } from 'antd';
import Markdown from 'markdown-to-jsx';
import config from 'react-global-configuration';

import history from 'customHistory';
import CustomLightbox from 'components/CustomLightbox';
import Meta from 'components/Meta';
import Utils from 'utils/util';
import styles from './style.module.css';
import './style.scss';

const {
  string, shape,
} = PropTypes;

class MD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: '',
    };
  }

  componentDidMount() {
    const {
      match: {
        params: {
          id: markdownFileName,
        },
      },
    } = this.props;

    Utils.loadMarkdownFile(markdownFileName)
      .then((content) => {
        if (content) {
          this.setState({
            content,
          });
        }
      })
      .catch(() => {
        history.push('/404');
      });
  }

  render() {
    const { content } = this.state;
    const {
      location: currentPathData,
      match: {
        params: {
          id: projectId,
        },
      },
    } = this.props;

    const projectsData = config.get('projects');
    if (projectId in projectsData) {
      const metaData = {
        currentPathData,
        ...projectsData[projectId],
      };
      const colSizes = {
        xs: {
          span: 20,
          offset: 2,
        },
        sm: {
          span: 20,
          offset: 2,
        },
        md: {
          span: 20,
          offset: 2,
        },
        lg: {
          span: 16,
          offset: 4,
        },
      };
      return (
        <Row className={cx('markdown', styles.project)}>
          <Meta {...metaData} />
          <Col {...colSizes}>
            <Markdown
              children={content}
              options={{
                overrides: {
                  Lightbox: {
                    component: CustomLightbox,
                  },
                  Carousel: {
                    component: Carousel,
                  },
                },
              }}
            />
          </Col>
        </Row>
      );
    }
    return null;
  }
}

MD.propTypes = {
  match: shape({
    params: shape({
      id: string,
    }),
  }).isRequired,
  location: shape({
    pathname: string.isRequired,
  }).isRequired,
};

export default MD;
