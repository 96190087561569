import React, { Component } from 'react';
import config from 'react-global-configuration';
import {
  Router,
  Route,
  Switch,
} from 'react-router-dom';

import history from 'customHistory';
import TemplateWrapper from 'components/TemplateWrapper';
import Home from 'pages/Home';
import Projects from 'pages/Projects';
import Markdown from 'pages/Markdown';
import Error404 from 'pages/Error404';

import './App.scss';

class App extends Component {
  render() {
    const redirectUrls = config.get('redirectUrls');
    return (
      <Router history={history}>
        <Switch>
          <Route
            exact
            path="/"
            component={TemplateWrapper(Home)}
          />
          <Route
            exact
            path="/projects"
            component={TemplateWrapper(Projects)}
          />
          <Route
            path="/project/:id"
            component={TemplateWrapper(Markdown)}
          />
          {
            Object.keys(redirectUrls).map(k => (
              <Route
                key={k}
                exact
                path={`/${k}`}
                render={() => {
                  window.location.assign(redirectUrls[k]);
                  return '';
                }}
              />

            ))

          }
          <Route component={TemplateWrapper(Error404)} />
        </Switch>
      </Router>
    );
  }
}

export default App;
