import React from 'react';
import PropTypes from 'prop-types';

import Meta from 'components/Meta';
import styles from './style.module.css';

const {
  string, shape,
} = PropTypes;

const Error404 = (props) => {
  const {
    location: currentPathData,
  } = props;

  const metaData = {
    title: 'Error 404',
    currentPathData,
  };
  return (
    <div className={styles.error}>
      <Meta {...metaData} />
      <div className={styles['error-code']}>404</div>
    </div>
  );
};

Error404.propTypes = {
  location: shape({
    pathname: string.isRequired,
  }).isRequired,
};

export default Error404;
