import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import config from 'react-global-configuration';

const { string, shape, array, oneOfType } = PropTypes;

const Meta = ({
  title, currentPathData, description, keywords,
}) => {
  const appName = config.get('appName');
  const appDomain = config.get('appDomain');
  const author = config.get('appAuthor');
  const defaultDescription = config.get('appDescription');
  const defaultKeywords = config.get('appKeywords');
  const { pathname } = currentPathData;

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{`${title ? `${title} |` : ''} ${appName}`}</title>
      <meta name="author" content={author} />
      <meta name="description" content={`${description || defaultDescription}`} />
      <meta name="keywords" content={`${keywords || defaultKeywords}`} />
      <link rel="canonical" href={`${appDomain}${pathname || ''}`} />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: null,
  currentPathData: null,
  description: null,
  keywords: null,
};

Meta.propTypes = {
  title: string,
  currentPathData: shape({
    pathname: string.isRequired,
  }),
  description: string,
  keywords: oneOfType([string, array]),
};


export default Meta;
