import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Row, Col } from 'antd';
import config from 'react-global-configuration';

import history from 'customHistory';
import Meta from 'components/Meta';
import Loader from 'components/Loader';
import Utils from 'utils/util';
import styles from './style.module.css';

const {
  string, shape,
} = PropTypes;

const cardSizes = {
  xs: 24, sm: 12, md: 8, lg: 8,
};
const cardDescriptionLength = 48;
const sort = {
  ASC: 'ASC',
  DESC: 'DESC',
};

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: sort.DESC,
      projects: [],
    };
  }

  componentDidMount() {
    const projectsData = config.get('projects');
    const promises = {};

    Object.keys(projectsData).map(async (key) => {
      promises[key] = Utils.loadMarkdownFile(key);
    });

    const promisesAll = Utils.resolvePromises(promises);
    promisesAll.then((filtered) => {
      const filteredProjects = Object.keys(filtered).map(key => ({
        key,
        ...projectsData[key],
      }));
      this.setState({
        projects: filteredProjects,
      });
    });
  }

  handleCardClick = (link) => {
    history.push(`/project/${link}`);
  }

  card = (item) => {
    const {
      key, title, description, images,
    } = item;
    const { cover } = images;
    const coverStyle = cover
      ? {
        background: `url(${cover})`,
        backgroundSize: 'cover',
        backgroundColor: '#eee',
        backgroundPosition: 'center',
      } : {};
    const descriptionShort = `${description.slice(0, cardDescriptionLength)}...`;

    return (
      <Col
        key={key}
        className={cx('gutter-row', styles['card-container'])}
        {...cardSizes}
      >
        <div className="gutter-box">
          <div
            role="button"
            tabIndex={0}
            onKeyPress={() => this.handleCardClick(key)}
            onClick={() => this.handleCardClick(key)}
            className={styles['custom-card']}
          >
            <div
              className={styles.cover}
              style={coverStyle}
            />
            <div className={styles['card-content']}>
              <div className={styles.title}>{title}</div>
              <div className={styles.description}>{descriptionShort}</div>
            </div>
          </div>
        </div>
      </Col>
    );
  }

  getProjects = () => {
    const { projects, sort: sortBy } = this.state;

    if (projects.length > 0) {
      return (
        projects
        .sort(({ timestamp: timestampA }, { timestamp: timestampB }) => {
          const keyA = new Date(timestampA);
          const keyB = new Date(timestampB);
          if (sortBy === sort.ASC) {
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
          } else {
            if (keyA > keyB) return -1;
            if (keyA < keyB) return 1;
          }
          return 0;
        })
        .map(project => (this.card(project)))
      );
    }
    return (<Loader />);
  }

  render() {
    const { projects } = this.state;
    const { location: currentPathData } = this.props;

    const metaData = {
      title: 'Projects',
      currentPathData,
    };
    const justify = projects.length > 0 ? 'start' : 'center';
    const colSizes = {
      xs: {
        span: 20,
        offset: 2,
      },
      sm: {
        span: 20,
        offset: 2,
      },
      md: {
        span: 20,
        offset: 2,
      },
      lg: {
        span: 16,
        offset: 4,
      },
    };

    return (
      <div className={styles.projects}>
        <Meta {...metaData} />
        <Row gutter={16} type="flex" justify={justify}>
          <Col {...colSizes}>
            <h1 className={styles.heading}>Projects</h1>
            {this.getProjects()}
          </Col>
        </Row>
      </div>
    );
  }
}

Projects.propTypes = {
  location: shape({
    pathname: string.isRequired,
  }).isRequired,
};


export default Projects;
