const projects = {
  ahaevent: {
    title: 'AhaEvent',
    keywords: ['design', 'development', 'open source'],
    description: 'AhaEvent is a web application designed and developed to showcase a curated list of all the FLOSS conferences.',
    images: {
      cover: 'images/ahaevent/banner.png',
    },
    timestamp: 1533061800000,
  },
  gfOffice: {
    title: 'GFOffice',
    keywords: ['design', 'prototype'],
    description: 'An android application to track and manage sales effortlessly',
    images: {
      cover: 'images/gf-office/logo-banner.png',
    },
    timestamp: 1533061800000,
  },
  firstaide: {
    title: 'FirstAide',
    keywords: ['design', 'development', 'open source'],
    description: 'A web application to help sexual assault victims',
    images: {
      cover: 'images/firstaide/banner.png',
    },
    timestamp: 1533061800000,
  },
  crowdr: {
    title: 'Crowdr',
    keywords: ['design', 'prototype'],
    description: 'A mobile application for providing ticketing solution for stadiums',
    images: {
      cover: 'images/crowdr/banner.jpg',
    },
    timestamp: 1533061800000,
  },
};
export default projects;
