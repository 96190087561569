import React from 'react';
import { Layout } from 'antd';
import config from 'react-global-configuration';

import styles from './style.module.css';

const {
  Footer,
} = Layout;

const CustomFooter = (props) => {
  const appName = config.get('appName');
  return (
    <Footer className={styles.footer} {...props}>
      &copy;
      {(new Date()).getFullYear()}
      {' '}
      {appName}
    </Footer>
  );
};

export default CustomFooter;
